















































































































































































































#main-container{
  height:100%;
  width:100%;
  overflow:hidden !important;
}

.iframe-container{
  height:100% !important;
}

header{
  display:none !important;
}

/* Initially hide the main content but keep it rendered */
.iframe-container {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

/* When loading finishes, fade it in */
.loading-visible {
  opacity: 1;
}

/* Keep it hidden during loading */
.loading-hidden {
  opacity: 0;
}

  //#store{
  //  width: 100%;
  //  height: 100%;
  //  margin: 0 0 0 15px;
  //}
  //.widgets{
  //    width: 100%;
  //    height: 30%;
  //}
  //.tools{
  //    width: 100%;
  //   height: 30%;
  //}
  //.funcionalidades{
  //    width: 100%;
  //    height:30%;
  //}

  //.carta-box {
  //    margin: 20px;
  //    width: 250px;
  //    height: 300px;
  //    position: relative;
  //    perspective: 1000px;
  //}

  //.carta-box:hover .carta {
  //    transform: rotateY(180deg);
  //}

  //.carta {
  //    transform-style: preserve-3d;
  //    transition: all 0.5s linear;
  //}

  //.cara {
  //    position: absolute;
  //    backface-visibility: hidden;
  //}

  //.cara.detras {
  //    transform: rotateY(180deg);
 //}
  //.price {
  //    background-color: black;
  //    position: absolute;
  //    color: white;
  //   top: 200px;
  //    left: 1px;
  //    width: 100%;
  //    font-weight: bold;
  //    font-size: 20px;
  //}
  //.title {
  //    background-color: black;
  //    position: absolute;
  //   color: white;
  //    top: 0px;
  //    left: 1px;
  //    width: 100%;
  //   font-weight: bold;
  //    font-size: 24px;
  //}
  //.description {
  //    text-align:center;
  //    background-color: #484b54;
  //    position: absolute;
  //    color: white;
  //    top: 0px;
  //    left: 1px;
  //    width: 100%;
  //    font-size: 16px;
  //}

